import { updateCartIconsAsync } from "./updateCartIcons";

// adds the dot with number of products in the cart
document.addEventListener("DOMContentLoaded", updateCartIconsAsync, {once: true});

// make shopping bag icon sticky
const headerDiv = document.querySelector("#main-nav");
const floatingShoppingBag = document.getElementById("floating_shopping_cart");


function handleIntersection(entries, observer) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      floatingShoppingBag.classList.remove("visible");
    } else {
      floatingShoppingBag.classList.add("visible");
    }
  });
}

if (floatingShoppingBag) {
  const navObserver = new IntersectionObserver(handleIntersection, {
    root: null,
    threshold: 0.4,
  });
  navObserver.observe(headerDiv);
}