const cartAmountContainers = document.querySelectorAll(".cart_amount");

export function updateCartIcons(itemsCount) {
  if(itemsCount !== null) {
    const floatingShoppingBag = document.getElementById("floating_shopping_cart");
    if (floatingShoppingBag) {
      if (itemsCount > 0) {
        floatingShoppingBag.classList.remove("no-products");
      } else {
        floatingShoppingBag.classList.add("no-products");
      }
    }

    let shoppingBagHTML = "";
    if (itemsCount > 0) {
      shoppingBagHTML = `<span class="cart-items-amount">${itemsCount}</span>`;
    } 
    
    cartAmountContainers.forEach(item => {
      item.innerHTML = shoppingBagHTML;
    });
  }
}


export async function updateCartIconsAsync() {
  if (cartAmountContainers.length > 0) {
    const response = await fetch("/store/_api/cart/");
    const cartObject = await response.json();
    updateCartIcons(cartObject.count);
  }
}
